// i18next-extract-mark-ns-start about-us
import React from 'react';
import styled from 'styled-components';

import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import {Content} from 'components/Content';
import {Section, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {ScreenSizes} from '../../types/responsive';
import {Bold} from 'components/StickyBanner';
import monei_team from '../images/monei_team.jpg';
import {AnchorLink} from 'components/AnchorLink';
import {ButtonLink} from 'components/Button';
import {Clients} from 'components/Clients';
import laVanguardia from '../images/la-vanguardia.png';
import forbes from '../images/forbes.png';
import elEconomista from '../images/el-economista.png';
import expansion from '../images/expansion.png';
import elMundo from '../images/el-mundo.png';
import xalaka from '../images/xalaka.png';
import bolsaMania from '../images/bolsa-mania.png';
import bancoDeEspana from '../images/banco_de_es_logo.svg';
import europeanCouncil from '../images/european_payments_council.png';
import pci from '../images/pci-logo.svg';
import shopify from '../images/shopify_logo.png';
import awsLogo from '../images/aws.png';
import redsysLogo from '../images/redsys.svg';
import visaLogo from '../images/visa-logo-small.svg';
import masterCardLogo from '../images/mastercard-logo-small.svg';
import deaLogo from '../images/dea_logo.png';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const Background = styled.div`
  position: relative;
  margin-top: 100px;
  padding-top: 40px;
  margin-bottom: 100px;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    margin-top: 60px;
    margin-bottom: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    top: 50px;
    bottom: 30px;
  }

  ${Section} {
    color: #ffffff;
    a {
      color: #ffffff;
    }
    a:hover {
      color: #efefef;
    }
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: skew(0, -8deg);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(40deg, #12e0c2 0%, #aab4e9 40%);
  }
`;

const IndexContent = styled.div`
  text-align: center;
  padding-top: 100px;
  padding-bottom: 0px;
  min-height: 300px;
  @media (max-width: ${ScreenSizes.md}) {
    min-height: 310px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    min-height: 210px;
  }
`;

const AboutUs: React.FC<PageProps> = (props: any) => {
  const {t, language} = useI18next();

  const {data} = props;

  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  const mediaLogos = [
    {
      logo: laVanguardia,
      url: {
        es: 'https://www.lavanguardia.com/'
      },
      alt: 'La Vanguardia',
      width: 200,
      scale: 1
    },
    {
      logo: forbes,
      url: {
        es: 'https://www.forbes.com/'
      },
      alt: 'Forbes',
      width: 200,
      scale: 1
    },
    {
      logo: elEconomista,
      url: {
        es: 'https://www.eleconomista.es/'
      },
      alt: 'El Economista',
      width: 200,
      scale: 1
    },
    {
      logo: expansion,
      url: {
        es: 'https://www.expansion.com/'
      },
      alt: 'Expansion',
      width: 200,
      scale: 1
    },
    {
      logo: elMundo,
      url: {
        es: 'https://www.elmundo.es/'
      },
      alt: 'El Mundo',
      width: 200,
      scale: 1
    },
    {
      logo: xalaka,
      url: {
        es: 'https://www.xalaka.com/'
      },
      alt: 'Xalaka',
      width: 200,
      scale: 1
    },
    {
      logo: bolsaMania,
      url: {
        es: 'https://www.bolsamania.com/'
      },
      alt: 'Bolsa Manía',
      width: 200,
      scale: 1
    }
  ];

  const partnersLogos = [
    {
      logo: bancoDeEspana,
      url: {
        es: 'https://www.bde.es/'
      },
      alt: 'Banco de España',
      width: 150,
      scale: 1
    },
    {
      logo: deaLogo,
      url: {
        es: 'https://www.europeanpayments.eu/'
      },
      alt: 'DEA',
      width: 150,
      scale: 1
    },
    {
      logo: europeanCouncil,
      url: {
        es: 'https://www.europeanpayments.eu/'
      },
      alt: 'European Payments Council',
      width: 150,
      scale: 1
    },
    {
      logo: pci,
      url: {
        es: 'https://www.europeanpayments.eu/'
      },
      alt: 'PCI',
      width: 150,
      scale: 1
    },
    {
      logo: shopify,
      url: {
        es: 'https://www.shopify.com/'
      },
      alt: 'Banco de España',
      width: 150,
      scale: 1
    },
    {
      logo: awsLogo,
      url: {
        es: 'https://aws.amazon.com/'
      },
      alt: 'AWS',
      width: 100,
      scale: 1
    },
    {
      logo: redsysLogo,
      url: {
        es: 'https://www.redsys.es/'
      },
      alt: 'Redsys',
      width: 150,
      scale: 1
    },
    {
      logo: visaLogo,
      url: {
        es: 'https://www.visa.com/'
      },
      alt: 'Visa',
      width: 150,
      scale: 1
    },
    {
      logo: masterCardLogo,
      url: {
        es: 'https://www.mastercard.com/'
      },
      alt: 'MasterCard',
      width: 110,
      scale: 1
    }
  ];

  return (
    <>
      <SEO
        path="about-us"
        title={t('About MONEI')}
        description={t(
          'MONEI is Southern Europe’s fastest-growing omnichannel payments platform, helping merchants sell more, save money on transaction fees, and reduce risk.'
        )}
      />
      <Content>
        <IndexContent>
          <SectionHeader underline tagName="h1">
            <Trans>About MONEI</Trans>
          </SectionHeader>
          <Trans parent="p">
            MONEI was founded in 2015 by{' '}
            <AnchorLink href="https://www.linkedin.com/in/alexandresaiz/" target="_blank">
              Alexandre Saiz Verdaguer
            </AnchorLink>
            , as a <Bold>fintech company</Bold> based in Malaga, Spain, that focuses on providing a
            comprehensive digital payment platform.
          </Trans>
          <Trans parent="p">
            The company's mission is to <Bold>simplify electronic payments</Bold> for businesses by
            offering an integrated system that supports a wide range of payment methods, including
            local and alternative options like Bizum, Apple Pay, Google Pay, and more.
          </Trans>
          <Trans parent="p">
            In 2021,{' '}
            <Bold>MONEI obtained its Payment Institution License from Banco de España.</Bold> By
            focusing on flexibility, security, and efficiency, MONEI aims to support business growth
            and streamline the payment process for merchants across Southern Europe and beyond.
          </Trans>
        </IndexContent>
      </Content>
      <Background>
        <Content sx={{marginTop: {md: '150px', sm: '70px'}}}>
          <Section centered>
            <SectionImage
              style={{width: '455px', borderRadius: '16px'}}
              mobileWidth={370}
              src={monei_team}
              alt="MONEI Team"
              title="MONEI Team"
            />
            <div>
              <SectionHeader>
                <Trans>The team behind MONEI</Trans>
              </SectionHeader>
              <Trans parent="p">
                MONEI employs an international team and is headquartered in Málaga. Even though we
                are working from different parts of Europe, our dedication to make this project grow
                together makes us a very united team, that comes together as often as possible.
              </Trans>
              <ButtonLink
                href="/page/we-are-hiring/"
                variant="dark"
                target="_blank"
                rel="noopener noreferrer">
                <Trans>Join the team!</Trans>
              </ButtonLink>
            </div>
          </Section>
        </Content>
      </Background>
      <Content>
        <Section
          centered
          column
          sx={{textAlign: 'center', paddingBottom: '20px', paddingTop: {sm: '90px'}}}>
          <SectionHeader underline>
            <Trans>MONEI Partners</Trans>
          </SectionHeader>
          <Trans parent="p">
            MONEI partners with financial institutions, international regulatory bodies, and other
            fintech companies to provide the best and most secure financial services.
          </Trans>
          <Clients clientList={partnersLogos} />
        </Section>
      </Content>
      <Content>
        <Section
          centered
          column
          sx={{textAlign: 'center', paddingBottom: '20px', paddingTop: {sm: '90px'}}}>
          <SectionHeader underline>
            <Trans>MONEI in the press</Trans>
          </SectionHeader>
          <Trans parent="p">
            Leading innovation in the payments sector, MONEI{' '}
            <AnchorLink href="/page/press/">frequently features</AnchorLink> in the most influencial
            generic and specialized publications.
          </Trans>
          <Clients clientList={mediaLogos} />
        </Section>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <Section
          centered
          column
          sx={{textAlign: 'center', paddingBottom: '20px', paddingTop: {sm: '90px'}}}>
          <SectionHeader underline>
            <Trans>MONEI Blog</Trans>
          </SectionHeader>
          <Trans parent="p">
            Our blog has become a reference on all things Fintech in Spain. <AnchorLink href="https://monei.com/blog" target="_blank">Check it</AnchorLink> and keep
            informed of novelties, trends and analysis on the payments everchanging landscape.
          </Trans>
        </Section>
      </Content>
    </>
  );
};

export default AboutUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "about-us"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
